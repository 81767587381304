.radio-button-group {
  &-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    text-align: center;
  }

  &-items-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    border: 1px solid $gray-300;
    background-color: transparent;
    line-height: 2.5rem;
    height: 2.5rem;
    margin: 0px auto;
    width: calc(100% - 4px);

    padding: 0.08rem 0.2rem;

    &.is-invalid {
      border-color: $red;
    }
  }

  &-button-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: $border-radius;
    border: solid 1px transparent;
    transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
      all 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    transform: translate3d(0, 0, 0);

    &:hover {
      border: solid 1px transparent;
      background-color: $secondary;
      cursor: pointer;
    }

    > [type='radio'] {
      width: 1px;
      height: 1px;
      border: 0;
      clip: rect(0 0 0 0);
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
    }

    &.checked {
      transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
        all 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
      transform: translate3d(0, 0, 0);
      height: 100%;
      background: $primary-gradient;

      &:hover {
        cursor: pointer;
      }
    }

    &:disabled {
      &:hover {
        background-color: transparent;
      }
    }

    > input {
      color: inherit;
      margin: 0;
      line-height: normal;
      box-sizing: border-box;
      padding: 0;

      ::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
      :-ms-input-placeholder {
        font-weight: 400;
      }
      &[disabled] {
        cursor: default;
      }
    }
  }

  &-button-label {
    display: inline-flex;
    padding: 0 0.9rem;
    color: $gray-500;
    font-weight: 400;
    box-sizing: border-box;
    margin: 0;

    &:hover {
      cursor: pointer;
      color: $primary;
    }

    &.checked {
      color: $white;
    }
  }

  &-marker-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    line-height: 2.2rem;
    height: 2.2rem;
  }

  &-marker {
    position: absolute;
    background: $primary-gradient;
    border: solid 1px $primary;
    opacity: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 2.2rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow-lift;
    transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
      all 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    transform: translate3d(0, 0, 0);
  }
}
