@import '../../assets/scss/stylesheets/media-queries';

.app-container {
  padding-bottom: 2.5rem;

  &.public {
    padding-top: 2.5rem;
  }

  @include desktop {
    padding-top: 0;
    padding-bottom: 0;
  }
}
