// creates a light version of the .nav

.nav-tabs-light {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.625rem 0 0.625rem;
  margin-bottom: 0;
  list-style: none;
  flex-direction: row;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

  .nav-link {
    padding: 0.2rem 0.625rem 0.2rem 0.625rem;
    border-bottom: $nav-tabs-link-active-border-width solid transparent;
    margin: 0.5rem 0rem;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }
  .nav-link.active {
    background: $frako-secondary;
    border-radius: 20px;
  }
}
