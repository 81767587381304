//
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

//
// Color system =====================================
//

$blue: #0098ff !default;
$indigo: #727cf5 !default; // NOT USED
$purple: #6b5eae !default;
$pink: #ff679b !default; // NOT USED
$red: #e63757 !default;
$orange: #fd7e14 !default; // NOT USED
$yellow: #ffc74c !default;
$green: #00d97e !default;
$teal: #02a8b5 !default; // NOT USED
$cyan: #39afd1 !default; // NOT USED

$white: #ffffff !default;
$gray-100: #f9fafc !default;
$gray-200: #edf2f9 !default;
$gray-300: #e3ebf6 !default;
$gray-400: #d2ddec !default;
$gray-500: #b1c2d9 !default;
$gray-600: #95aac9 !default;
$gray-700: #6e84a3 !default;
$gray-800: #3b506c !default;
$gray-900: #283e59 !default;
$black: #12263f !default;

$frako-primary-100: #773843;
$frako-primary-200: #603039;
$frako-primary-300: #3d1b22;
$frako-primary-400: #2a1519;
$frako-primary-500: #1d0c10;

$frako-secondary: #f2dfd0;

$primary: $frako-primary-200 !default;
$secondary: $frako-secondary !default;

$text-muted: $gray-700 !default;

$primary-gradient: linear-gradient(
  90deg,
  $frako-primary-500 0%,
  $frako-primary-200 50%,
  $frako-primary-100 100%
);

// Paths variables
$path-to-fonts: './../../fonts' !default;
$path-to-img: '../../img' !default;

//
// Spinner
//
$spinner-overlay-opacity: 0.8;
$spinner-color-1: $primary;
$spinner-color-2: $frako-primary-300;

//
// Border Radius
//
$border-radius: 1.5rem !default;
$border-radius-lg: 1.5rem !default;
$border-radius-sm: 1.5rem !default;
$border-radius-xs: 1.5rem !default;
$badge-border-radius: 0.25rem !default;

//
// Toast
//
$toast-border-radius-mobile: 0;

// Tooltips
$tooltip-bg: $frako-secondary !default;

// DatePicker

$datepicker__background-color: $white !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $frako-primary-300 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 1rem !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;

// Breakpoint overrides
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
