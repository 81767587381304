.print-wrapper {
  display: none;
}

.confirmed-order-print {
  .parameters {
    h3 {
      color: $frako-primary-100;
    }

    .filters {
      display: flex;

      .filter {
        text-align: center;
        margin-right: 10px;
        margin-top: auto;
        width: 10rem;
      }

      .active {
        border-color: $frako-primary-100;
        color: $frako-primary-100;
      }
    }
  }

  padding: 40px !important;
  page-break-before: auto;

  .line-above {
    border-top: solid 1px $gray-200;
  }

  .lines tr {
    border-bottom: solid 1px $gray-200;
  }

  .lines tr:last-of-type {
    border: none;
  }

  .blank-row {
    height: 50px;
  }

  .no-page-break {
    page-break-inside: avoid;
  }

  .catalog {
    break-after: always;
    width: 100%;
  }
  .article-list {
    margin-top: 2rem;
  }
  .total {
    page-break-inside: avoid;
    padding-top: 5rem;
  }
  .overview {
    break-before: always;
    margin-top: 5rem;
  }
}
