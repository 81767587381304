.form-select:focus-within,
.input-group:focus-within,
.radio-button-group-container:focus-within,
.tagify:focus-within {
  border-style: none;
  border-radius: $border-radius !important;
  box-shadow: $input-btn-focus-box-shadow !important;
}

.invalid-feedback {
  display: block;
}

// Radio button focus
.custom-control:focus-within {
  .custom-control-label::after {
    box-shadow: $input-btn-focus-box-shadow;
    border-radius: 0.625rem;
  }
}

.form-select:focus-within,
.input-group:focus-within,
.input-focus-group:focus-within {
  * {
    border-style: none;
  }
}
