//
// borders.scss
//

// Sizing

@each $size, $value in $border-sizing {
  .border-#{$size} {
    border-width: $border-width * $value !important;
  }

  .border-top-#{$size} {
    border-top-width: $border-width * $value !important;
  }

  .border-right-#{$size} {
    border-right-width: $border-width * $value !important;
  }

  .border-bottom-#{$size} {
    border-bottom-width: $border-width * $value !important;
  }

  .border-left-#{$size} {
    border-left-width: $border-width * $value !important;
  }
}

// Contextual classes

.border-body {
  border-color: $body-bg !important;
}

.border-card {
  border-color: $card-bg !important;
}

// Adds responsive borders options to bootstrap
// ex.: border-lg-left (border will appear only above lg breakpoint)
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up(#{$breakpoint}) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
    }
  }
}
