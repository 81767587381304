body .tagify {
  --tag-bg: #{$secondary};
  --tags-border-color: #{$gray-400};
  --tags-hover-border-color: #{$gray-400};
  --tags-focus-border-color: #{$primary};
  --tag-text-color: #{$black};
  --tag-pad: 0.5em 0.6em;
  --tag-inset-shadow-size: 1.3em;
  --tag-invalid-color: #{$danger};
  --placeholder-color: #{$gray-500};
  --placeholder-color-focus: #{$gray-500};
  --input-color: #{$black};

  border-radius: $border-radius;

  &__tag {
    > div {
      border-radius: $border-radius;
    }
  }

  &__dropdown {
    $dropdown: &;
    $trans: 0.25s cubic-bezier(0, 1, 0.5, 1);
    position: absolute;
    z-index: 9999;
    transform: translateY(1px);
    overflow: hidden;

    &[placement='top'] {
      margin-top: 0;
      transform: translateY(-100%);
      #{$dropdown}__wrapper {
        border-top-width: 1px;
        border-bottom-width: 0;
      }
    }

    // when the dropdown shows next to the caret while typing
    &[position='text'] {
      box-shadow: 0 0 0 3px rgba(var(--tagify-dd-color-primary), 0.1);
      font-size: 0.9em;
      #{$dropdown}__wrapper {
        border-width: 1px;
      }
    }

    &__wrapper {
      max-height: 300px;
      overflow: hidden;
      // background: $tagify-dd-bg-color;
      background: var(--tagify-dd-bg-color);
      // border: 1px solid $tags-focus-border-color;
      border-color: $gray-300;
      border-width: 1.1px; // fixes - https://bugs.chromium.org/p/chromium/issues/detail?id=1147523
      border-top-width: 0;
      box-shadow: 0 2px 4px -2px rgba(black, 0.2);
      // box-sizing: border-box;
      transition: $trans;

      border-radius: $border-radius;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;

      &:hover {
        overflow: auto;
      }
    }

    // intial state, pre-rendered
    &--initial {
      #{$dropdown}__wrapper {
        max-height: 20px;
        transform: translateY(-1em);
      }

      &[placement='top'] {
        #{$dropdown}__wrapper {
          transform: translateY(2em);
        }
      }
    }

    &__item {
      box-sizing: inherit;
      padding: 0.3em 0.5em;
      margin: 1px;
      cursor: pointer;
      border-radius: 2px;
      position: relative;
      outline: none;

      &--active {
        background: $secondary;
        // background: var(--tagify-dd-color-primary);
        color: $black;
      }
      &:active {
        filter: brightness(105%);
      }
    }
  }
}

:root {
  --tagify-dd-color-primary: #{$primary};
}
