.form-label {
  margin-left: 0.8rem;
}

.project-document-filter {
  .form-group {
    margin-bottom: 0;
  }
}
.confirmation-filter {
  width: 100%;
  margin-bottom: -400px;
  z-index: 1000;
}

.form-group.form-checkbox-buttons {
  .first-row {
    .btn {
      &:first-child {
        border-top-left-radius: $border-radius;
      }
      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }
  }

  .last-row {
    .btn {
      &:first-child {
        border-bottom-left-radius: $border-radius;
      }
      &:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  .btn {
    border-radius: 0;

    &:hover {
      background-color: $frako-secondary;
      color: $frako-primary-200;
    }
  }
}
