// Vertical
//
// Creates a vertically aligned version of the navbar

.navbar-vertical {
  // Expanded
  //
  // Expanded navbar specific styles

  transition: all 0.4s ease-in-out;

  &.navbar-expand {
    @each $breakpoint, $value in $grid-breakpoints {
      &-#{$breakpoint} {
        // overflow-x: visible;

        @include media-breakpoint-up(#{$breakpoint}) {
          .navbar-collapse {
            > * {
              min-width: 100%;
            }
          }
        }
      }
    }
  }

  .btn-collapse {
    position: absolute;
    min-width: 15px;
    min-width: unset;
    padding: 0.4rem;

    .icon {
      transition: 0.3s ease-in-out;
      transform: rotate(0deg);
    }

    top: 27px;
    right: 10px;
  }

  .nav-icon {
    width: 18px;
    height: 100%;

    display: flex;
    justify-content: center;
  }
}

.navbar-vertical-sm {
  &.navbar-expand {
    @each $breakpoint, $value in $grid-breakpoints {
      &-#{$breakpoint} {
        @include media-breakpoint-up(#{$breakpoint}) {
          .navbar-nav .nav .nav-link {
            padding-left: $navbar-vertical-padding-x;
            padding-right: 0;
          }
        }
      }
    }
  }

  @include desktop {
    .app-logo {
      width: 100%;
    }
  }

  .btn-collapse {
    padding: 0.3rem;
    top: 18px;
    right: -11px;

    .icon {
      transform: rotate(180deg);
    }
  }

  .nav-icon {
    display: inline;
  }
}
