// ----------------------------------------------------------------------
// Break-points
// ----------------------------------------------------------------------

// 348 px
$xxs-mobile: 348px;
// 576 px
$xs-mobile: 576px;
// 768 px
$mobile-width: 768px;
// 992 px
$tablet-width: 992px;
// 1100 px
$desktop-width: 1100px;
// 1700 px
$extra-large-width: 1600px;

// ----------------------------------------------------------------------
// Media Queries Mixins
// ----------------------------------------------------------------------

@mixin xxs-mobile {
  @media (max-width: #{$xxs-mobile - 1}) {
    @content;
  }
}

@mixin xs-mobile {
  @media (max-width: #{$xs-mobile - 1}) {
    @content;
  }
}

@mixin from-xs-mobile {
  @media (min-width: #{$xs-mobile}) {
    @content;
  }
}

@mixin from-mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width - 1}) {
    @content;
  }
}

@mixin until-tablet {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin large-screens {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin extra-large-screens {
  @media (min-width: #{$extra-large-width}) {
    @content;
  }
}

@page {
  size: A4;
  margin: 20mm;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: A4;
    margin: 10mm;
  }
  .PrintSection {
    padding: 40px !important;
    page-break-before: auto;

    .no-page-break {
      page-break-inside: avoid;
    }

    .card {
      display: block;
      border: none;
    }
    .catalog {
      break-after: always;
      width: 100%;
    }
    .article-list {
      margin-top: 2rem;
    }
    .total {
      page-break-inside: avoid;
      padding-top: 5rem;
    }
    .overview {
      break-before: always;
      margin-top: 5rem;
    }
  }
}
