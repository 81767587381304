.btn {
  background-size: 200% auto;
  transition: 0.3s;

  &:hover {
    background-position: right center; /* change the direction of the change here */
  }
}

.btn-primary {
  background-image: $primary-gradient;
}

.btn-group {
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      background-image: none;
      background-image: $value;
    }
  }
}

.invisible-button {
  background: white;
  border: none;
}

.filter-buttons {
  button :first-child {
    margin-left: 0rem !important;
  }
  button.btn-primary {
    margin-left: 1rem;
  }
  button {
    margin-left: 0.4rem;
  }
  button :last-child {
    margin-right: 0rem !important;
  }
}
