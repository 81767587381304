//
// flatpickr.scss
// Flatpickr plugin overrides
//

.flatpickr-calendar {
  background-color: $input-bg;
  border: $input-border-width solid $input-border-color;
  color: $input-color;
  box-shadow: none;
  width: 100%;
  * {
    color: inherit !important;
    fill: currentColor !important;
  }

  &.arrowTop:before {
    border-bottom-color: $input-border-color;
  }

  &.arrowTop:after {
    border-bottom-color: $input-bg;
  }

  .flatpickr-months {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    top: 0.625rem;
  }

  .flatpickr-current-month {
    font-size: 115%;
  }

  .flatpickr-day {
    border-radius: $border-radius;

    &:hover {
      background-color: $light;
      border-color: $input-border-color;
    }
  }

  .flatpickr-day.prevMonthDay {
    color: $text-muted !important;
  }

  .flatpickr-day.today {
    border-color: $border-color;
  }

  .flatpickr-day.selected {
    background-color: $primary;
    border-color: $primary;
    color: $white !important;
  }

  .flatpickr-day.inRange {
    background-color: $light;
    border: none;
    border-radius: 0;
    box-shadow: -5px 0 0 $light, 5px 0 0 $light;
  }
}
