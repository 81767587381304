// Toastify styles override

.Toastify {
  &__toast {
    border-radius: $toast-border-radius;
    padding: $toast-padding-x;

    font-family: $font-family-sans-serif;

    &--default {
      background: $rt-color-default;
      color: $body-color;
    }
    &--info {
      background: $info;
    }
    &--success {
      background: $success;
    }
    &--warning {
      color: $body-color;
      background: $yellow;
    }
    &--error {
      background: $danger;
    }
  }

  &__progress-bar {
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.8);

    &--default {
      background: $body-color;
    }

    &--warning {
      background-color: $body-color;
    }
  }

  &__close-button {
    &--warning {
      color: $body-color;
      opacity: 0.3;
    }
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    border-radius: $toast-border-radius-mobile;
  }
}
