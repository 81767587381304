.table-row-order-article .checkbox,
.modal-checkbox .checkbox,
.mobile-table-row-order-article .checkbox * {
  cursor: pointer;
}

.table-row-order-article .checkbox,
.mobile-table-row-order-article .checkbox {
  padding-left: 27px;
  width: 1rem;
  height: 1rem;
  margin-top: -10px;
}

.modal-checkbox .checkbox {
  padding-left: 21px;
  width: 1rem;
  height: 1rem;
  margin-top: -20px;
  padding-bottom: 28px;
}

.table-row-order-article .checkbox label,
.modal-checkbox .checkbox label,
.mobile-table-row-order-article .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.table-row-order-article .checkbox label::before,
.modal-checkbox .checkbox label::before,
.mobile-table-row-order-article .checkbox label::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 0;
  margin-left: -21px;
  border-radius: 3px;
  background-color: $gray-900;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.table-row-order-article .checkbox label::after,
.modal-checkbox .checkbox label::after,
.mobile-table-row-order-article .checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 0;
  margin-left: -21px;
}

.table-row-order-article .checkbox input[type='checkbox'],
.modal-checkbox .checkbox input[type='checkbox'],
.mobile-table-row-order-article .checkbox input[type='checkbox'] {
  opacity: 0;
  z-index: 10;
}

.table-row-order-article .checkbox input[type='checkbox']:checked + label::after,
.modal-checkbox .checkbox input[type='checkbox']:checked + label::after,
.mobile-table-row-order-article .checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

.table-row-order-article .checkbox .checkbox-circle label::before,
.modal-checkbox .checkbox .checkbox-circle label::before,
.mobile-table-row-order-article .checkbox .checkbox-circle label::before {
  border-radius: 50%;
}

.table-row-order-article .checkbox .checkbox-inline,
.modal-checkbox .checkbox .checkbox-inline,
.mobile-table-row-order-article .checkbox .checkbox-inline {
  margin-top: 0;
}

.table-row-order-article .checkbox-red input[type='checkbox']:checked + label::after,
.modal-checkbox .checkbox-red input[type='checkbox']:checked + label::after,
.mobile-table-row-order-article .checkbox-red input[type='checkbox']:checked + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
  background-size: 0.6rem;

  background-repeat: no-repeat;

  background-position: center;
  background-color: $frako-primary-100;
  border-radius: 50%;
}

.table-row-order-article .checkbox-red input[type='checkbox'] + label::before,
.modal-checkbox .checkbox-red input[type='checkbox'] + label::before,
.mobile-table-row-order-article .checkbox-red input[type='checkbox'] + label::before {
  background-color: $gray-300;
  border-radius: 50%;
}
