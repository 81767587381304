//
// user.scss
// Use this to write your custom SCSS
//

@import './stylesheets/media-queries';
@import './stylesheets/navbar';
@import './stylesheets/buttons';
@import './stylesheets/spinner';
@import './stylesheets/toast';
@import './stylesheets/card';
@import './stylesheets/pagination';
@import './stylesheets/forms';
@import './stylesheets/tagify';
@import './stylesheets/nav';
@import './stylesheets/list-group';
@import './stylesheets/radio-button-group';
@import './stylesheets/borders';
@import './stylesheets/modal';
@import './stylesheets/focus';
@import './stylesheets/tailored-body';
@import './stylesheets/date-picker';
@import './stylesheets/checkbox';
@import './stylesheets/table';
@import './stylesheets/print';

// Extra utilities classes
.cursor-pointer {
  cursor: pointer;
}

//truncate
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: none;
}

// App logo
.app-logo {
  width: 150px;
  transition: all 0.5s ease-in-out;

  &.public {
    @include desktop {
      min-width: 16.25rem;
      height: 3rem;
    }
  }
}

.mail-sent-illustration {
  max-height: 80px;

  @include from-mobile {
    max-height: 140px;
  }
}

// Entity list
.action-icon {
  &:hover,
  &:focus {
    color: $frako-primary-100;
  }

  &:not(:first-of-type) {
    margin-left: 0.5rem;
  }
}

// Project Document header
.project-document-header {
  padding: 1rem;
}

.flex-1 {
  flex: 1;
}

// Font helper
$font-size-xs: ($font-size-base * 0.71104) !default; // 10px

.xsmall {
  font-size: $font-size-xs;
}

// form-input with icon
.form-control-icon {
  border: none;
  background-color: transparent;
  &:disabled {
    outline: none !important;
    border: none !important;
    background-color: #f9fafc;
  }
}
.input-group-icon {
  color: #12263f;
  background-color: white;

  border: 1px solid #edf2f9;
  outline: 0;
  border-radius: 1.25rem;
  textarea {
    resize: none;
  }
}

// input type checkbox large version
.large-checkbox {
  width: 20px;
  height: 20px;
}

.no-border {
  tr:first-child {
    td {
      border-top: none;
    }
  }
}

.filter-window {
  display: inline-block;
  z-index: 1001;
  padding: 0.5rem;
  position: fixed;
  top: 10rem;
  right: 12rem;
  @media (max-width: 992px) {
    z-index: 1001;
    top: 20%;
    position: fixed;
    top: 20%;
    left: 0%;
  }
}

.table-row-order-article {
  th:first-child {
    border-top-left-radius: 1.5rem !important;
  }
  th:last-child {
    border-top-right-radius: 1.5rem !important;
  }
}

.order-article-list {
  :first-child {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  :last-child {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
}

.remarkInput {
  display: flex;
  align-items: center;
}
//project print article list
tr {
  .price {
    padding: 0 1rem 0 0;
    width: 5%;
  }
}

// project print article overveiw list
.printArticleTableOverview {
  td {
    border: $gray-300 solid 1px;
    width: 13rem;
    font-weight: bold;
    text-align: right;
  }
}

.printArticleRow {
  td {
    vertical-align: top !important;
    padding: 0.5rem;
    border: none;
    text-align: right;
  }
}

.table-cell-list li {
  display: inline !important;
  list-style-type: none;
  margin-right: 0.1rem;
}
