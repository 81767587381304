.project-catalogs-list,
.project-articles-list {
  @extend .list-group;
  max-height: 50rem;
  overflow-y: auto;
  overflow-x: hidden;

  /* Scrolbar styles */
  /* width */
  &::-webkit-scrollbar {
    width: 0.3125rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $gray-200;
    border-radius: 50rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 50rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($color: $secondary, $amount: 10);
  }

  .catalog-list-item,
  .article-list-item {
    position: relative;
    // display: block;
    padding: $list-group-item-padding-y $list-group-item-padding-x;

    margin-bottom: 0.5rem;
    color: $list-group-color;
    background-color: $list-group-bg;
    border-width: $list-group-border-width;
    border-style: solid;
    border-color: transparent;
    @include border-radius(3rem);
    display: flex;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      @include border-radius(3rem);

      box-shadow: $box-shadow;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    // Hover state
    @include hover-focus {
      z-index: 1; // Place hover/focus items above their siblings for proper border styling
      background-color: $list-group-hover-bg;

      &::after {
        opacity: 1;
      }
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:first-child {
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled,
    &:disabled {
      color: $list-group-disabled-color;
      pointer-events: none;
      background-color: $list-group-disabled-bg;
    }

    .active {
      z-index: 2; // Place active items above their siblings for proper border styling
    }
  }

  .catalog-list-item {
    align-items: center;

    &.active {
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  .article-list-item {
    align-items: flex-start;
    background-color: $white;

    &.active {
      color: $white;
      background: $primary-gradient;
      border-color: $secondary;
    }

    &.is-dragging {
      background-color: $secondary;
    }

    &.is-invalid {
      color: $red;
    }
  }
}

.tailored-list-item {
  align-items: flex-start;
  background-color: $white;
  color: $list-group-color;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: bold;

  &.active {
    border-width: $list-group-border-width;
    border-style: solid;
    border-color: transparent;
    @include border-radius(3rem);
  }

  &:hover {
    background-color: $gray-100;
    @include border-radius(3rem);
  }
  &.active {
    background-color: $gray-300;
  }
}
